/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('change-password', {
      parent: 'root',
      url: '/change-password',
      controller: 'acpChangePasswordSectionCtrl',
      params: {
        successState: null
      },
      templateUrl: 'apps/change-password/templates/acp-change-password.ng.html',
      data: {
        permissions: {
          except: ['hub_user', 'isPaypalLinkedAccount'],
          redirectTo: {
            hub_user: 'change-password-hub',
            isPaypalLinkedAccount: 'dashboard'
          }
        }
      },
      resolve: {
        $userInfo: /* @ngInject */ function(module, acpSimpleAccountModel) {
          return acpSimpleAccountModel.get();
        },
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.apps.change-password" */ 'apps/change-password').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('change-password-hub', {
      parent: 'root',
      url: '/change-password',
      templateUrl:
        'apps/change-password/templates/acp-change-password-hub.ng.html',
      controller: 'AcpChangePasswordHubSectionCtrl',
      params: {
        successState: null
      },
      data: {
        permissions: {
          only: 'hub_user',
          redirectTo: {
            hub_user: 'change-password'
          }
        }
      },
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.apps.change-password-hub" */ 'apps/change-password').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    });
}

export default states;
